import React, { useState, useEffect } from 'react'
import Box from '@commerce-ui/core2/Box'
import { lin } from '@commerce-ui/core2'
import Link from '@link'
import Container from '@commerce-ui/core2/Container'
import Image from '@image'
import PropTypes from 'prop-types'
import putImageSizes from '../../helpers/putImageSizes'
import IconArrow from '../icons/IconArrow'
import styledBox from '../../styledBox'
import Video from '../Video'
import { styled } from '../../components/stitches/stitches'
import { inherits } from 'util'

const Root = styled('div', {
  bg: '$cream',
  transition: 'opacity 300ms',
  position: 'relative',
  clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0)',
  opacity: 0,
  color: '$black',
  variants: {
    loaded: {
      true: {
        opacity: 1
      }
    },
    isWhite: {
      true: {
        color: '$white'
      }
    }
  }
})

const GridStyled = styled('div', {
  display: 'grid',
  minHeight: '400px',
  gridTemplateRows: '100vh',
  gridTemplateColumns: '1fr',
  height: '80vh',
  '@lg': {
    height: '100vh'
  },
  variants: {
    mobileImage: {
      true: {
        gridTemplateRows: 'inherit',
        height: 'inherit',
        '@lg': {
          gridTemplateRows: '100vh',
          height: 'inherit'
        }
      }
    },
    image2: {
      true: {
        '@lg': {
          gridTemplateColumns: '1fr 1fr'
        }
      }
    }
  }
})

const VideoWrapper = styled('div', {
  width: '100%',
  height: '100%',
  variants: {
    hideOnMobile: {
      true: {
        display: 'none',
        '@lg': {
          display: 'block'
        }
      }
    }
  }
})

const VideoMobileWrapper = styled('div', {
  display: 'block',
  '@lg': {
    display: 'none'
  }
})

//TODO stitches:
const Image1Wrapper = styledBox(
  {
    display: (props) => (props.hideOnMobile ? ['none', null, null, 'block'] : null)
  },
  { fitW: true, fitH: true }
)
//TODO stitches:
const Image2Wrapper = styledBox(
  {
    overflow: 'hidden',
    display: ['none', null, null, 'block']
  },
  { fitW: true, fitH: true }
)

const ImageMobileWrapper = styled('div', {
  display: 'block',
  '@lg': {
    display: 'none'
  }
})

const LabelRoot = styled('div', {
  top: 0,
  left: 0,
  width: '100%',
  pointerEvents: 'none',
  position: 'absolute',
  height: '75vh',
  '@lg': {
    height: '100vh'
  },
  variants: {
    sticky: {
      true: {
        position: 'fixed'
      }
    },
    mobileImage: {
      true: {
        height: '90vh',
        '@lg': {
          height: '90vh'
        }
      }
    }
  }
})

const LabelWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'start',
  textAlign: 'left',
  '@lg': {
    justifyContent: 'center'
  },
  variants: {
    centerTextOnMobile: {
      true: {
        justifyContent: 'center',
        textAlign: 'center'
      }
    }
  }
})

const LabelWrapperGrid = styled('div', {
  display: 'inline-grid',
  projectFont: 'heading05',
  gridTemplateColumns: '1fr 1fr',
  '@lg': {
    gridTemplateColumns: '1fr'
  },
  lin: {
    gridRowGap: ['$6', '$8'],
    gridColumnGap: ['$10', '$12']
  },
  variants: {
    stackLabelsOnMobile: {
      true: {
        gridTemplateColumns: '1fr',
        '@lg': {
          gridTemplateColumns: '1fr'
        }
      }
    },
    stackLabelsOnDesktop: {
      true: {
        '@lg': {
          gridTemplateColumns: '1fr 1fr'
        }
      }
    }
  }
})

const Label1 = styled('div', {
  justifySelf: 'start',
  textAlign: 'center',
  '@lg': {
    justifySelf: 'center'
  },
  variants: {
    withLabel2: {
      true: {
        textAlign: 'inherit',
        '@lg': {
          justifySelf: 'end'
        }
      }
    },
    centerTextOnMobile: {
      true: {
        justifySelf: 'center',
        '@lg': {
          justifySelf: 'center'
        }
      }
    }
  }
})

const Label2 = styled('div', {
  display: 'flex',
  alignItems: 'center'
})

const ArrowWrapper = styled('div', {
  transform: 'rotate(180deg)',
  mr: 's5',
  '@lg': {
    display: 'none'
  }
})

const BottomGradient = styled('div', {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '30%',
  minHeight: '100px',
  pointerEvents: 'none',
  variants: {
    isWhite: {
      true: {
        background: 'linear-gradient( 0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)'
      }
    }
  }
})

const BottomTextWrapper = styled('div', {
  boxSizing: 'border-box',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  containerMargin: 'px',
  pointerEvents: 'none',
  projectFont: 'heading03',
  justifyContent: 'flex-start',
  textAlign: 'left',
  lin: {
    py: ['$9', '$10']
  },
  '@lg': {
    justifyContent: 'center',
    textAlign: 'center'
  },
  variants: {
    centerTextOnMobile: {
      true: {
        justifyContent: 'center',
        textAlign: 'center'
      }
    }
  },
  a: {
    textDecoration: 'underline'
  },
  '>div': {
    maxWidth: '250px',
    pointerEvents: 'all'
  }
})

const Banner = ({
  image1,
  image2,
  video1,
  video2,
  mobileImage,
  video,
  mobileVideo,
  label1,
  label2,
  label2hasArrow,
  href,
  bottomText,
  bottomComponent,
  isWhite,
  stickyLabels,
  centerTextOnMobile,
  stackLabelsOnMobile,
  imagePosition,
  eagerLoading
}) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  })

  let _imagePosition = 'center ' + imagePosition ? imagePosition : 'center'

  return (
    <Root isWhite={isWhite} loaded={loaded}>
      <GridStyled image2={image2 ? true : false} mobileImage={mobileImage ? true : false}>
        {image1 && !video1 && (
          <Image1Wrapper hideOnMobile={mobileImage || mobileVideo}>
            <Image
              image={image1}
              sizes={putImageSizes(['100vw', null, null, image2 ? '50vw' : '100vw'])}
              objectPosition={_imagePosition}
            />
          </Image1Wrapper>
        )}
        {video1 && (
          <VideoWrapper hideOnMobile={mobileImage || mobileVideo ? true : false}>
            <Video video={video1} objectFit={'cover'} />
          </VideoWrapper>
        )}
        {image2 && !video2 && (
          <Image2Wrapper>
            <Image
              image={image2}
              sizes={putImageSizes(['100vw', null, null, '50vw'])}
              objectPosition={_imagePosition}
              loading={eagerLoading ?? 'eager'}
            />
          </Image2Wrapper>
        )}
        {video2 && (
          <VideoWrapper hideOnMobile={mobileImage || mobileVideo ? true : false}>
            <Video video={video2} objectFit={'cover'} />
          </VideoWrapper>
        )}
        {mobileImage && (
          <ImageMobileWrapper>
            <Image
              image={mobileImage}
              sizes={'100vw'}
              objectPosition={_imagePosition}
              loading={eagerLoading ?? 'eager'}
            />
          </ImageMobileWrapper>
        )}
        {video && (
          <VideoWrapper hideOnMobile={mobileVideo ? true : false}>
            <Video video={video} objectFit={'cover'} />
          </VideoWrapper>
        )}
        {mobileVideo && (
          <VideoMobileWrapper>
            <Video video={mobileVideo} objectFit={'cover'} />
          </VideoMobileWrapper>
        )}
      </GridStyled>

      <LabelRoot sticky={stickyLabels} mobileImage={mobileImage ? true : false}>
        <Container
          style={{
            height: '100%',
            position: 'relative'
          }}
        >
          <LabelWrapper centerTextOnMobile={centerTextOnMobile}>
            <LabelWrapperGrid
              stackLabelsOnDesktop={label2 ? true : false}
              stackLabelsOnMobile={stackLabelsOnMobile || !label2 ? true : false}
            >
              {label1 && (
                <Label1
                  withLabel2={label2 ? true : false}
                  centerTextOnMobile={centerTextOnMobile}
                  dangerouslySetInnerHTML={{ __html: label1 }}
                />
              )}
              {label2 && (
                <Label2>
                  {label2hasArrow && (
                    <ArrowWrapper>
                      <IconArrow size={24} />
                    </ArrowWrapper>
                  )}
                  <Box dangerouslySetInnerHTML={{ __html: label2 }} />
                </Label2>
              )}
            </LabelWrapperGrid>
          </LabelWrapper>
        </Container>
      </LabelRoot>

      {(bottomText || bottomComponent) && (
        <>
          <BottomGradient isWhite={isWhite} />
          <BottomTextWrapper centerTextOnMobile={centerTextOnMobile}>
            {bottomText && <div dangerouslySetInnerHTML={{ __html: bottomText }} />}
            {bottomComponent && bottomComponent}
          </BottomTextWrapper>
        </>
      )}

      {href && (
        <Link
          href={href}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        />
      )}
    </Root>
  )
}

Banner.defaultProps = {
  isWhite: true,
  stackLabelsOnMobile: true
}

Banner.propTypes = {
  image1: PropTypes.object,
  image2: PropTypes.object,
  video: PropTypes.object,
  mobileVideo: PropTypes.object,
  label1: PropTypes.string,
  label2: PropTypes.string,
  href: PropTypes.string,
  bottomText: PropTypes.string,
  bottomComponent: PropTypes.object,
  isWhite: PropTypes.bool,
  stackLabelsOnMobile: PropTypes.bool,
  centerTextOnMobile: PropTypes.bool
}

export default Banner
